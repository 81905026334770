@import "abstracts/_abstracts";
.AppAlert {
	display: flex;
	font-size: .8em;
	background: var(--colorThemeAccent);
	overflow: hidden;
	border-radius: 4px;
	box-shadow: 0 0 20px #0000001a;
	line-height: 1.5;
	margin-bottom: var(--spaceMd);
	
	a {
		color: inherit;
		text-decoration: underline;
		
		@include on-event {
			text-decoration: none;
		}
	}

	&-icon {
		background: currentColor;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 1em;

		svg {
			color: #fff;
			width: 1em;
		}
	}

	&-text {
		color: var(--colorText);
		padding: 1.2em;
	}

	&--info {
		color: var(--stateInfo);
	}

	&--success {
		color: var(--stateSuccess);
	}

	&--warning {
		color: var(--stateWarning);
	}

	&--error {
		color: var(--stateError);
	}
}